// src/components/FarmingActivity.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import FarmingCardComponent from './FarmingCardComponent';
import ItemSelection from './ItemSelection';
import { toast } from 'react-toastify';
import { FaClock, FaLeaf } from 'react-icons/fa';

const FarmingActivity = () => {
  const { activity } = useParams();
  const navigate = useNavigate();
  const [step, setStep] = useState('card-selection');
  const [availableCards, setAvailableCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [farmableItems, setFarmableItems] = useState([]);
  const [error, setError] = useState(null);
  const [inventoryFull, setInventoryFull] = useState(false);
  const [farmingSlots, setFarmingSlots] = useState({ total: 1, used: 0 });

  const normalizeActivityName = (name) => {
    return name.toLowerCase().replace('-', '_');
  };

  const getActivityEmoji = (activityName) => {
    switch (normalizeActivityName(activityName)) {
      case 'mining': return '⛏️';
      case 'fishing': return '🎣';
      case 'woodcutting': return '🪓';
      case 'herbalism': return '🌿';
      case 'treasure_hunting': return '💎';
      default: return '🔹';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [cardsResponse, profileResponse, farmingStatusResponse] = await Promise.all([
          axios.get(`/profile/cards-with-skill/${normalizeActivityName(activity)}`),
          axios.get('/profile'),
          axios.get('/profile/farming-status')
        ]);
        
        setAvailableCards(cardsResponse.data);
        setFarmingSlots({
          total: profileResponse.data.farmingSlots.total,
          used: farmingStatusResponse.data.filter(job => job.status === 'active').length
        });
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch necessary data. Please try again.');
      }
    };

    fetchData();
  }, [activity]);

  const handleCardSelect = async (card) => {
    setSelectedCard(card);
    try {
      const [itemsResponse, profileResponse] = await Promise.all([
        axios.get(`/profile/farmable-items/${card.userCardId}?skill=${normalizeActivityName(activity)}`),
        axios.get('/profile')
      ]);
      setFarmableItems(itemsResponse.data);
      setInventoryFull(profileResponse.data.inventory.length >= profileResponse.data.backpack.slots);
      setStep('item-selection');
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch necessary data. Please try again.');
    }
  };

  const handleStartFarming = async (item) => {
    if (farmingSlots.used >= farmingSlots.total) {
      toast.error('No farming slots available. Please wait for a current job to finish or buy more slots.');
      return;
    }

    try {
      await axios.post('/profile/farm', { 
        userCardId: selectedCard.userCardId, 
        itemId: item._id 
      });
      navigate('/farming');
      toast.success('Farming started successfully!');
    } catch (error) {
      console.error('Error starting farming:', error);
      if (error.response && error.response.data.msg === 'No farming slots available') {
        toast.error('No farming slots available. Please wait for a current job to finish or buy more slots.');
      } else {
        toast.error('Failed to start farming. Please try again.');
      }
    }
  };

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className="container mx-auto mt-8 p-4">
      <h2 className="text-3xl font-bold mb-6 text-center flex items-center justify-center">
        <span className="mr-2">{getActivityEmoji(activity)}</span>
        <span className="capitalize">{activity.replace('-', ' ')} Farming</span>
      </h2>
      
      {farmingSlots.used >= farmingSlots.total && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
          <p className="font-bold">Warning</p>
          <p>All farming slots are in use. Wait for a job to finish or buy more slots to start a new farming job.</p>
        </div>
      )}
      
      {step === 'card-selection' && (
        <div>
          <h3 className="text-2xl font-semibold mb-4">Select a Card to Farm With</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {availableCards.map((card) => (
              <div 
                key={card.userCardId} 
                onClick={() => !card.isFarming && handleCardSelect(card)} 
                className={`relative cursor-pointer transform transition-transform duration-200 ${card.isFarming ? 'opacity-90' : 'hover:scale-105'}`}
              >
                <FarmingCardComponent 
                  card={card} 
                  activitySkill={normalizeActivityName(activity)}
                  isFarming={card.isFarming}
                />
                {card.isFarming && card.farmingDetails && (
                  <div className="absolute inset-0 bg-black bg-opacity-30 backdrop-filter backdrop-blur-sm flex flex-col items-center justify-center rounded-lg p-4">
                    <span className="text-white font-bold text-lg mb-2">Currently Farming</span>
                    <div className="text-yellow-300 flex items-center mb-1">
                      <FaLeaf className="mr-2" />
                      <span>{card.farmingDetails.itemName}</span>
                    </div>
                    <div className="text-blue-300 flex items-center">
                      <FaClock className="mr-2" />
                      <span>{calculateRemainingTime(card.farmingDetails.startTime, card.farmingDetails.endTime)}</span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {step === 'item-selection' && (
        <ItemSelection 
          items={farmableItems} 
          onSelectItem={handleStartFarming}
          selectedCard={selectedCard}
          inventoryFull={inventoryFull}
          activityEmoji={getActivityEmoji(activity)}
          farmingSlotsAvailable={farmingSlots.used < farmingSlots.total}
        />
      )}
    </div>
  );
};

const calculateRemainingTime = (startTime, endTime) => {
  const now = new Date();
  const end = new Date(endTime);
  const diff = end - now;
  
  if (diff <= 0) return 'Completed';
  
  const hours = Math.floor(diff / 3600000);
  const minutes = Math.floor((diff % 3600000) / 60000);
  
  return `${hours}h ${minutes}m`;
};

export default FarmingActivity;