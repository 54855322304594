import React from 'react';
import { FaFistRaised, FaShieldAlt } from 'react-icons/fa';

const CardComponent = ({ card, uniqueKey }) => {
  const getRarityStyle = (rarity) => {
    switch (rarity.toLowerCase()) {
      case 'common': return 'border-gray-400 shadow-gray-400/50';
      case 'uncommon': return 'border-green-400 shadow-green-400/50';
      case 'epic': return 'border-purple-400 shadow-purple-400/50';
      case 'legendary': return 'border-yellow-400 shadow-yellow-400/50';
      default: return 'border-gray-400 shadow-gray-400/50';
    }
  };

  const getRarityTextStyle = (rarity) => {
    switch (rarity.toLowerCase()) {
      case 'common': return 'text-gray-200 bg-gray-600';
      case 'uncommon': return 'text-green-200 bg-green-600';
      case 'epic': return 'text-purple-200 bg-purple-600';
      case 'legendary': return 'text-yellow-200 bg-yellow-600';
      default: return 'text-gray-200 bg-gray-600';
    }
  };

  const getSkillEmoji = (skillName) => {
    switch (skillName) {
      case 'mining': return '⛏️';
      case 'fishing': return '🎣';
      case 'woodcutting': return '🪓';
      case 'herbalism': return '🌿';
      case 'treasure_hunting': return '💎';
      default: return '🔹';
    }
  };

  const getExperienceForNextLevel = (currentLevel) => {
    return Math.floor(100 * Math.pow(1.5, currentLevel));
  };

  const rarityClass = getRarityStyle(card.rarity);

  return (
    <div key={uniqueKey} className={`w-64 h-96 rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl bg-gradient-to-br from-gray-800 to-gray-900 border-2 ${rarityClass}`}>
      <div className="relative h-48">
        <img src={card.image} alt={card.name} className="w-full h-full object-cover" />
        <div className="absolute top-0 left-0 right-0 p-2 flex justify-between items-center bg-gradient-to-b from-black to-transparent">
          <h3 className="text-sm font-bold text-white truncate flex-grow" title={card.name}>{card.name}</h3>
          <span className={`text-xs font-semibold px-2 py-1 rounded-full ${getRarityTextStyle(card.rarity)}`}>
            {card.rarity}
          </span>
        </div>
        <div className="absolute bottom-0 left-0 right-0 p-2 flex justify-between items-center bg-gradient-to-t from-black to-transparent">
          <span title="Attack" className="flex items-center text-white bg-red-600 rounded-full px-2 py-1 text-sm">
            <FaFistRaised className="mr-1" /> {card.attack}
          </span>
          <span title="Defense" className="flex items-center text-white bg-blue-600 rounded-full px-2 py-1 text-sm">
            <FaShieldAlt className="mr-1" /> {card.defense}
          </span>
        </div>
      </div>
      <div className="p-2 h-48 overflow-y-auto custom-scrollbar">
        <div className="grid grid-cols-3 gap-2">
          {card.skills.map((skill) => {
            const nextLevelExp = getExperienceForNextLevel(skill.level);
            const expPercentage = (skill.experience / nextLevelExp) * 100;
            return (
              <div key={skill.name} className="flex flex-col items-center" title={`${skill.name} (Lvl ${skill.level})`}>
                <div className="text-lg mb-1">{getSkillEmoji(skill.name)}</div>
                <span className="text-xs font-semibold text-white">
                  Lvl {skill.level}
                </span>
                <div className="w-full bg-gray-700 rounded-full h-1 mt-1">
                  <div 
                    className="bg-blue-400 h-1 rounded-full" 
                    style={{ width: `${expPercentage}%` }}
                  ></div>
                </div>
                <span className="text-xxs text-gray-400 mt-1 whitespace-nowrap">
                  {skill.experience}/{nextLevelExp}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CardComponent;