import React from 'react';
import { Link } from 'react-router-dom';
import { FaWarehouse, FaChessKnight, FaHammer, FaStore, FaGift, FaUsers, FaUserFriends, FaQuestionCircle } from 'react-icons/fa';
import { GiCardRandom, GiFarmTractor, GiCrossedSwords } from 'react-icons/gi';
import { useUser } from '../contexts/UserContext';

const Sidebar = ({ isOpen }) => {
  const { isAuthenticated } = useUser();

  if (!isAuthenticated) return null;

  return (
    <div className={`fixed left-0 top-0 bg-gray-800 text-white w-56 min-h-screen flex flex-col transition-all duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0`}>
      <div className="p-4 mb-4 flex justify-center">
        <img 
          src="https://iili.io/dUxbaEl.png"
          alt="Project Idleamesta logo" 
          className="w-3/4 h-auto"
        />
      </div>
      <nav className="flex-1">
        <ul className="space-y-2 px-4">
          {/* Play Section */}
          <li className="text-gray-400 uppercase text-xs tracking-wider">Play</li>
          <li>
            <Link to="/farming" className="flex items-center p-2 hover:bg-gray-700 rounded">
              <GiFarmTractor className="mr-3" /> Farming
            </Link>
          </li>
          <li>
            <Link to="/inventory" className="flex items-center p-2 hover:bg-gray-700 rounded">
              <FaWarehouse className="mr-3" /> Inventory
            </Link>
          </li>
          <li>
            <Link to="/cards" className="flex items-center p-2 hover:bg-gray-700 rounded">
              <GiCardRandom className="mr-3" /> Cards
            </Link>
          </li>
          <li>
            <Link to="/crafting" className="flex items-center p-2 hover:bg-gray-700 rounded">
              <FaHammer className="mr-3" /> Crafting
            </Link>
          </li>
          <li>
            <Link to="/battle" className="flex items-center p-2 hover:bg-gray-700 rounded">
              <GiCrossedSwords className="mr-3" /> Battle
            </Link>
          </li>
          <li>
            <Link to="/farm" className="flex items-center p-2 hover:bg-gray-700 rounded">
              <GiFarmTractor className="mr-3" /> Farm
            </Link>
          </li>

          {/* Market Section */}
          <li className="mt-6 text-gray-400 uppercase text-xs tracking-wider">Market</li>
          <li>
            <Link to="/market" className="flex items-center p-2 hover:bg-gray-700 rounded">
              <FaStore className="mr-3" /> Market
            </Link>
          </li>
          <li>
            <Link to="/vendor" className="flex items-center p-2 hover:bg-gray-700 rounded">
              <FaGift className="mr-3" /> Vendor
            </Link>
          </li>

          {/* Social Section */}
          <li className="mt-6 text-gray-400 uppercase text-xs tracking-wider">Social</li>
          <li>
            <Link to="/guild" className="flex items-center p-2 hover:bg-gray-700 rounded">
              <FaUsers className="mr-3" /> Guild
            </Link>
          </li>
          <li>
            <Link to="/friends" className="flex items-center p-2 hover:bg-gray-700 rounded">
              <FaUserFriends className="mr-3" /> Friends
            </Link>
          </li>

          {/* Other Section */}
          <li className="mt-6 text-gray-400 uppercase text-xs tracking-wider">Other</li>
          <li>
            <Link to="/quest" className="flex items-center p-2 hover:bg-gray-700 rounded">
              <FaQuestionCircle className="mr-3" /> Quest
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;