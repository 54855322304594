import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from '../axiosConfig';
import { toast } from 'react-toastify';
import { FaStar, FaArrowUp, FaCoins } from 'react-icons/fa';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [notifiedJobs, setNotifiedJobs] = useState(new Set());

  const fetchUserInfo = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setUserInfo(null);
      setIsAuthenticated(false);
      return;
    }
    try {
      const response = await axios.get('/profile');
      console.log('User info fetched:', response.data);
      setUserInfo(response.data);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Error fetching user info:', error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        setUserInfo(null);
        setIsAuthenticated(false);
      }
    }
  }, []);

  const login = async (token) => {
    localStorage.setItem('token', token);
    await fetchUserInfo();
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUserInfo(null);
    setIsAuthenticated(false);
  };

  const updateUserInfo = (newInfo) => {
    setUserInfo(prevInfo => ({ ...prevInfo, ...newInfo }));
  };
  
  const handleFarmingComplete = useCallback((job) => {
    if (!job) return;
  
    console.log('Farming completed:', job);
  
    toast(
      <div className="flex items-center">
        <div className="flex-shrink-0 mr-3">
          <img 
            src={job.itemImage} 
            alt={job.itemName} 
            className="w-10 h-10 object-cover rounded"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = 'https://via.placeholder.com/40?text=?';
            }}
          />
        </div>
        <div className="flex-grow">
          <h4 className="font-bold text-sm mb-1">Farming Complete: {job.itemName}</h4>
          <div className="text-xs space-y-1">
            <div className="flex items-center">
              <FaStar className="text-blue-500 mr-1" />
              <span>{job.cardName}: +{job.totalExperienceGained} XP ({job.skillName})</span>
              {job.skillLevelUps > 0 && (
                <span className="ml-1 text-green-500 flex items-center">
                  <FaArrowUp className="mr-1" />
                  +{job.skillLevelUps}
                </span>
              )}
            </div>
            <div className="flex items-center">
              <FaStar className="text-purple-500 mr-1" />
              <span>Player: +{Math.floor(job.totalExperienceGained / 10)} XP</span>
              {job.playerLevelUps > 0 && (
                <span className="ml-1 text-green-500 flex items-center">
                  <FaArrowUp className="mr-1" />
                  +{job.playerLevelUps}
                </span>
              )}
            </div>
            <div className="flex items-center">
              <FaStar className="text-green-500 mr-1" />
              <span>Items Farmed: {job.totalItemsFarmed}</span>
            </div>
          </div>
        </div>
      </div>,
      {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'bg-gray-800 text-white',
        toastId: `farming-complete-${job.jobId}`,
      }
    );
  
    fetchUserInfo();
  }, [fetchUserInfo]);

  const checkCompletedFarmingJobs = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const response = await axios.get('/profile/farming-status');
        response.data.forEach(job => {
          if (job.status === 'completed' && !job.notified && !notifiedJobs.has(job.jobId)) {
            handleFarmingComplete(job);
            // Mark the job as notified in the backend
            axios.post('/profile/mark-farming-notified', { jobId: job.jobId })
              .then(() => {
                // Add the job to the notified set only after successful backend update
                setNotifiedJobs(prev => new Set(prev).add(job.jobId));
              })
              .catch(error => console.error('Error marking job as notified:', error));
          }
        });
      } catch (error) {
        console.error('Error checking farming jobs:', error);
      }
    }
  }, [isAuthenticated, handleFarmingComplete, notifiedJobs]);

  const handleItemFarmed = useCallback((data) => {
    console.log('Handling farmed item:', data);
    
    // Check if the event is for the current user
    if (data.username === userInfo.username) {
      toast(
        <div className="flex items-center">
          <div className="flex-shrink-0 mr-3">
            <img 
              src={data.itemImage} 
              alt={data.itemName} 
              className="w-10 h-10 object-cover rounded"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = 'https://via.placeholder.com/40?text=?';
              }}
            />
          </div>
          <div className="flex-grow">
            <h4 className="font-bold text-sm mb-1">Item Farmed: {data.itemName}</h4>
            <p className="text-xs">Quantity: {data.quantity} (Total: {data.totalQuantity})</p>
          </div>
        </div>,
        {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: 'bg-gray-800 text-white',
        }
      );
    }
  
    console.log('Updating user info with new item data');
    setUserInfo(prevInfo => {
      if (!prevInfo) {
        console.log('No previous user info available');
        return null;
      }
      console.log('Previous inventory:', prevInfo.inventory);
      const updatedInventory = prevInfo.inventory.map(item => 
        item.item._id === data.itemId 
          ? { ...item, quantity: data.totalQuantity }
          : item
      );
      console.log('Updated inventory:', updatedInventory);
      return {
        ...prevInfo,
        inventory: updatedInventory
      };
    });
  }, [userInfo]);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          await fetchUserInfo();
        } catch (error) {
          console.error('Error verifying authentication:', error);
          logout();
        }
      }
    };
    checkAuth();
  }, [fetchUserInfo]);

  useEffect(() => {
    if (isAuthenticated && userInfo) {
      const userInfoInterval = setInterval(fetchUserInfo, 60000);
      const farmingCheckInterval = setInterval(checkCompletedFarmingJobs, 5000);
  
      const token = localStorage.getItem('token');
      const sseUrl = `${process.env.REACT_APP_API_URL}/api/sse?token=${token}`;
      console.log('Connecting to SSE:', sseUrl);
      const eventSource = new EventSource(sseUrl);
  
      eventSource.onopen = () => {
        console.log('SSE connection opened');
      };
  
      eventSource.onmessage = (event) => {
        console.log('SSE event received:', event.data);
        const data = JSON.parse(event.data);
        handleItemFarmed(data);
      };
  
      eventSource.onerror = (error) => {
        console.error('SSE Error:', error);
        eventSource.close();
      };
  
      return () => {
        clearInterval(userInfoInterval);
        clearInterval(farmingCheckInterval);
        console.log('Closing SSE connection');
        eventSource.close();
      };
    }
  }, [isAuthenticated, userInfo, fetchUserInfo, checkCompletedFarmingJobs, handleItemFarmed]);

  return (
    <UserContext.Provider value={{ 
      userInfo, 
      isAuthenticated, 
      updateUserInfo, 
      fetchUserInfo, 
      login, 
      logout,
      handleFarmingComplete,
      checkCompletedFarmingJobs
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);