import React, { useState } from 'react';
import axios from '../axiosConfig';
import CardComponent from './CardComponent';
import { useUser } from '../contexts/UserContext';
import { toast } from 'react-toastify';

const OpenPacks = () => {
  const [openedCards, setOpenedCards] = useState(null);
  const [error, setError] = useState(null);
  const [isOpening, setIsOpening] = useState(false);
  const { userInfo, fetchUserInfo } = useUser();

  const handleOpenPack = async () => {
    setIsOpening(true);
    setError(null);
    try {
      const response = await axios.post('/profile/open-pack');
      setOpenedCards(response.data.cards);
      
      // Actualizar la información del usuario después de abrir el pack
      await fetchUserInfo();
      
      toast.success('Pack opened successfully!');
    } catch (error) {
      console.error('Error opening pack:', error);
      setError(error.response?.data?.msg || 'Error opening pack');
      toast.error(error.response?.data?.msg || 'Error opening pack');
    } finally {
      setIsOpening(false);
    }
  };

  return (
    <div className="container mx-auto mt-8 p-4">
      <h2 className="text-2xl font-bold mb-4">Open Card Packs</h2>
      <div className="mb-4">
        <p>Current Gold: {userInfo?.gold || 0}</p>
      </div>
      <button 
        onClick={handleOpenPack}
        disabled={isOpening || (userInfo?.gold < 100)}
        className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
          (isOpening || userInfo?.gold < 100) ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        {isOpening ? 'Opening...' : 'Open Pack (100 Gold)'}
      </button>
      
      {error && <div className="text-red-500 mt-4">{error}</div>}
      
      {openedCards && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-4">Opened Cards:</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {openedCards.map((card, index) => (
              <div key={`${card._id}-${index}`} className="flex justify-center">
                <CardComponent card={card} uniqueKey={`${card._id}-${index}`} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default OpenPacks;