import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import { FaStar, FaCoins, FaBoxes, FaStore, FaShoppingCart, FaBox, FaShoppingBag, FaPlus } from 'react-icons/fa';
import { useUser } from '../contexts/UserContext';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, item }) => {
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    setQuantity(1);
  }, [isOpen]);

  if (!isOpen) return null;

  const incrementQuantity = () => {
    if (quantity < item.quantity) setQuantity(quantity + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  const setMaxQuantity = () => {
    setQuantity(item.quantity);
  };

  const totalGold = item.goldValue * quantity;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-4 rounded-lg shadow-xl max-w-sm w-full">
        <h3 className="text-lg font-bold mb-2 text-white">Confirm Sale</h3>
        <p className="text-gray-300 mb-2 text-sm">
          Are you sure you want to sell {item.name}?
        </p>
        <div className="flex items-center justify-between mb-2">
          <span className="text-gray-300 text-sm">Quantity:</span>
          <div className="flex items-center">
            <button 
              onClick={decrementQuantity}
              className="bg-gray-700 text-white p-1 rounded-l"
            >
              -
            </button>
            <span className="bg-gray-600 text-white px-2 py-1 text-sm">{quantity}</span>
            <button 
              onClick={incrementQuantity}
              className="bg-gray-700 text-white p-1"
            >
              +
            </button>
            <button 
              onClick={setMaxQuantity}
              className="bg-blue-500 text-white px-2 py-1 rounded-r text-sm"
            >
              MAX
            </button>
          </div>
        </div>
        <p className="text-gray-300 mb-2 flex items-center text-sm">
          <FaCoins className="text-yellow-400 mr-1" />
          Total gold: {totalGold}
        </p>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-3 py-1 bg-gray-600 text-white rounded hover:bg-gray-700 text-sm"
          >
            Cancel
          </button>
          <button
            onClick={() => onConfirm(quantity)}
            className="px-3 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600 text-sm"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const BuySlotModal = ({ isOpen, onClose, onConfirm, price }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-4 rounded-lg shadow-xl max-w-sm w-full">
        <h3 className="text-lg font-bold mb-2 text-white">Confirm Purchase</h3>
        <p className="text-gray-300 mb-4">
          Are you sure you want to buy a new inventory slot for {price} gold?
        </p>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-3 py-1 bg-gray-600 text-white rounded hover:bg-gray-700"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-3 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const Inventory = () => {
  const [inventory, setInventory] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userGold, setUserGold] = useState(0);
  const [backpack, setBackpack] = useState({ slots: 0, usedSlots: 0 });
  const [slotPrice, setSlotPrice] = useState(0);
  const [isBuySlotModalOpen, setIsBuySlotModalOpen] = useState(false);
  const navigate = useNavigate();
  const { userInfo, updateUserInfo, fetchUserInfo } = useUser();

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const [inventoryResponse, profileResponse] = await Promise.all([
          axios.get('/profile/inventory'),
          axios.get('/profile')
        ]);
        setInventory(inventoryResponse.data.items || []);
        setUserGold(profileResponse.data.gold);
        setBackpack({
          slots: profileResponse.data.backpack.slots,
          usedSlots: inventoryResponse.data.items.length
        });
        setSlotPrice(profileResponse.data.backpack.slotPrice);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else {
          setError(error.response?.data?.msg || 'Error fetching data');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchInventory();
  }, [navigate]);

  const handleSellToVendor = () => {
    setIsModalOpen(true);
  };

  const handleConfirmSale = async (quantity) => {
    try {
      const response = await axios.post('/profile/sell-item', {
        itemId: selectedItem.id,
        quantity: quantity
      });
      
      updateUserInfo({ gold: response.data.newGoldAmount });
      setInventory(prevInventory => 
        prevInventory.map(item => 
          item.id === selectedItem.id
            ? { ...item, quantity: item.quantity - quantity }
            : item
        ).filter(item => item.quantity > 0)
      );
      
      setBackpack(prevBackpack => ({
        ...prevBackpack,
        usedSlots: prevBackpack.usedSlots - (selectedItem.quantity === quantity ? 1 : 0)
      }));
  
      if (selectedItem.quantity === quantity) {
        setSelectedItem(null);
      } else {
        setSelectedItem(prev => ({ ...prev, quantity: prev.quantity - quantity }));
      }
  
      setIsModalOpen(false);
  
      toast.success(`Successfully sold ${quantity} ${selectedItem.name} for ${response.data.soldItem.saleValue} gold!`);
      
      // Actualizar la información del usuario después de la venta
      fetchUserInfo();
    } catch (error) {
      console.error('Error selling item:', error);
      setError(error.response?.data?.msg || 'Error selling item');
      toast.error('Failed to sell item. Please try again.');
    }
  };

  const handleBuySlot = async () => {
    try {
      const response = await axios.post('/profile/buy-inventory-slot');
      setUserGold(response.data.newGold);
      setBackpack(prevBackpack => ({
        ...prevBackpack,
        slots: response.data.newSlots
      }));
      setSlotPrice(response.data.newSlotPrice);
      setIsBuySlotModalOpen(false);
      fetchUserInfo();
      toast.success('New inventory slot purchased successfully!');
    } catch (error) {
      console.error('Error buying inventory slot:', error);
      toast.error(error.response?.data?.msg || 'Failed to buy inventory slot');
    }
  };

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  }
  
  const freeSlots = backpack.slots - backpack.usedSlots;

  return (
    <div className="container mx-auto mt-4 p-4">
      <h2 className="text-2xl font-bold mb-4 text-center text-black">Inventory</h2>
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full lg:w-2/3">
          <div className="bg-gray-800 p-4 rounded-lg shadow-xl">
            <div className="flex justify-between items-center mb-4">
              <span className="text-lg font-semibold text-gray-200">Your Items</span>
              <div className="bg-blue-500 text-white text-sm font-bold px-2 py-1 rounded-full flex items-center">
                <FaShoppingBag className="mr-1" />
                {backpack.usedSlots} / {backpack.slots}
              </div>
            </div>
            <div className="grid grid-cols-6 sm:grid-cols-7 md:grid-cols-8 lg:grid-cols-7 xl:grid-cols-8 gap-2">
              {inventory.map((item) => (
                <button
                  key={item.id}
                  onClick={() => setSelectedItem(item)}
                  className={`relative bg-gray-700 rounded-lg p-1 transition-all duration-200 hover:bg-gray-600 ${
                    selectedItem?.id === item.id ? 'ring-2 ring-blue-400' : ''
                  }`}
                  data-tooltip-id={`tooltip-${item.id}`}
                >
                  <div className="absolute top-0 left-0 bg-blue-500 text-white text-xs px-1 rounded-tl-lg rounded-br-lg">
                    {item.quantity}x
                  </div>
                  <img 
                    src={item.image} 
                    alt={item.name} 
                    className="w-full h-14 sm:h-16 object-contain" 
                  />
                  <Tooltip id={`tooltip-${item.id}`} place="top" className="z-50 custom-tooltip">
                    <div className="p-2 bg-gray-700 rounded-lg shadow-lg border border-gray-600">
                      <h3 className="font-bold text-sm mb-1 text-yellow-400">{item.name}</h3>
                      <p className="text-gray-300 text-xs">{item.type}</p>
                    </div>
                  </Tooltip>
                </button>
              ))}
              {[...Array(freeSlots)].map((_, index) => (
                <div
                  key={`empty-${index}`}
                  className="bg-gray-700 rounded-lg p-1 flex items-center justify-center"
                  style={{ height: 'calc(3.5rem + 0.5rem)' }} // 14px (h-14) + 2px (p-1 top and bottom)
                >
                  <span className="text-gray-500 text-xs">Empty</span>
                </div>
              ))}
              <button
                onClick={() => setIsBuySlotModalOpen(true)}
                className="bg-gray-700 rounded-lg p-1 flex flex-col items-center justify-center text-yellow-500 hover:bg-gray-600 transition-colors duration-200"
                style={{ height: 'calc(3.5rem + 0.5rem)' }} // 14px (h-14) + 2px (p-1 top and bottom)
              >
                <FaPlus className="mb-1" />
                <span className="text-xs">Buy Slot</span>
                <span className="text-xs mt-1">{slotPrice} <FaCoins className="inline" /></span>
              </button>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/3">
          <div className="bg-gray-800 p-4 rounded-lg shadow-xl h-full">
          {selectedItem ? (
            <div className="flex flex-col h-full">
              <div className="flex items-center mb-4">
                <img 
                  src={selectedItem.image} 
                  alt={selectedItem.name} 
                  className="w-20 h-20 object-contain mr-4"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'https://via.placeholder.com/80?text=?';
                  }}
                />
                <div>
                  <h3 className="text-xl font-bold text-yellow-400">{selectedItem.name}</h3>
                  <p className="text-sm text-gray-400 italic">{selectedItem.type}</p>
                </div>
              </div>
              <p className="text-sm text-gray-300 mb-4">{selectedItem.description}</p>
              <div className="bg-gray-700 rounded-lg p-4 mb-4 flex-grow">
                <div className="grid grid-cols-2 gap-y-2 text-sm">
                  <div className="flex items-center">
                    <FaStar className="text-green-400 mr-2" />
                    <span className="text-gray-300">Skill:</span>
                  </div>
                  <span className="text-right text-white font-medium">{selectedItem.skill}</span>
                  
                  <div className="flex items-center">
                    <FaCoins className="text-yellow-400 mr-2" />
                    <span className="text-gray-300">Value:</span>
                  </div>
                  <span className="text-right text-white font-medium">{selectedItem.goldValue}</span>
                  
                  <div className="flex items-center">
                    <FaBoxes className="text-purple-400 mr-2" />
                    <span className="text-gray-300">Quantity:</span>
                  </div>
                  <span className="text-right text-white font-medium">{selectedItem.quantity}</span>
                </div>
              </div>
              <div className="space-y-2 mt-auto">
                <button 
                  className="w-full bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded text-sm transition duration-300 flex items-center justify-center"
                  onClick={handleSellToVendor}
                >
                  <FaStore className="mr-2" /> Sell to vendor
                </button>
                <button className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded text-sm transition duration-300 flex items-center justify-center">
                  <FaShoppingCart className="mr-2" /> List on market
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-full text-gray-400">
              <FaBox className="text-4xl mb-2" />
              <p className="text-center text-sm">Select an item to view details</p>
            </div>
          )}
          </div>
        </div>
      </div>
      <BuySlotModal
        isOpen={isBuySlotModalOpen}
        onClose={() => setIsBuySlotModalOpen(false)}
        onConfirm={handleBuySlot}
        price={slotPrice}
      />
      <ConfirmationModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmSale}
        item={selectedItem}
      />
    </div>
  );
};

export default Inventory;