// src/components/FarmingCardComponent.js
import React from 'react';
import { FaStar } from 'react-icons/fa';

const FarmingCardComponent = ({ card, activitySkill, isFarming }) => {
  const getRarityStyle = (rarity) => {
    switch (rarity.toLowerCase()) {
      case 'common': return 'border-gray-400 shadow-gray-400/50';
      case 'uncommon': return 'border-green-400 shadow-green-400/50';
      case 'epic': return 'border-purple-400 shadow-purple-400/50';
      case 'legendary': return 'border-yellow-400 shadow-yellow-400/50';
      default: return 'border-gray-400 shadow-gray-400/50';
    }
  };

  const getRarityTextStyle = (rarity) => {
    switch (rarity.toLowerCase()) {
      case 'common': return 'text-gray-200 bg-gray-600';
      case 'uncommon': return 'text-green-200 bg-green-600';
      case 'epic': return 'text-purple-200 bg-purple-600';
      case 'legendary': return 'text-yellow-200 bg-yellow-600';
      default: return 'text-gray-200 bg-gray-600';
    }
  };

  const getSkillEmoji = (skillName) => {
    switch (skillName) {
      case 'mining': return '⛏️';
      case 'fishing': return '🎣';
      case 'woodcutting': return '🪓';
      case 'herbalism': return '🌿';
      case 'treasure_hunting': return '💎';
      default: return '🔹';
    }
  };

  const getExperienceForNextLevel = (currentLevel) => {
    return Math.floor(100 * Math.pow(1.5, currentLevel));
  };

  const relevantSkill = card.skills.find(skill => skill.name.toLowerCase() === activitySkill.toLowerCase());
  const rarityClass = getRarityStyle(card.rarity);

  if (!relevantSkill) {
    return null; // or some fallback UI
  }

  const nextLevelExp = getExperienceForNextLevel(relevantSkill.level);
  const expPercentage = (relevantSkill.experience / nextLevelExp) * 100;

  return (
    <div className={`relative w-64 h-96 rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl bg-gradient-to-br from-gray-800 to-gray-900 border-2 ${rarityClass} ${isFarming ? 'opacity-50' : ''}`}>
      <div className="relative h-48">
        <img src={card.image} alt={card.name} className="w-full h-full object-cover" />
        <div className="absolute top-0 left-0 right-0 p-2 flex justify-between items-center bg-gradient-to-b from-black to-transparent">
          <h3 className="text-sm font-bold text-white truncate flex-grow" title={card.name}>{card.name}</h3>
          <span className={`text-xs font-semibold px-2 py-1 rounded-full ${getRarityTextStyle(card.rarity)}`}>
            {card.rarity}
          </span>
        </div>
      </div>
      <div className="p-4 h-48 overflow-y-auto custom-scrollbar">
        <div className="flex items-center justify-between mb-2">
          <span className="text-2xl">{getSkillEmoji(activitySkill)}</span>
          <span className="text-lg font-semibold text-white">{activitySkill}</span>
          <span className="text-xl font-bold text-yellow-400">Lvl {relevantSkill.level}</span>
        </div>
        <div className="mt-4">
          <div className="flex justify-between text-sm text-gray-300 mb-1">
            <span>EXP: {relevantSkill.experience}</span>
            <span>Next: {nextLevelExp}</span>
          </div>
          <div className="w-full bg-gray-700 rounded-full h-2">
            <div 
              className="bg-blue-500 h-2 rounded-full transition-all duration-300 ease-out"
              style={{ width: `${expPercentage}%` }}
            ></div>
          </div>
          <div className="text-right text-xs text-gray-400 mt-1">
            {expPercentage.toFixed(1)}%
          </div>
        </div>
      </div>
      {isFarming && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-lg">
          <span className="text-white font-bold">Currently Farming</span>
        </div>
      )}
    </div>
  );
};

export default FarmingCardComponent;