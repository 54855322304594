import React, { useEffect, useState, useCallback } from 'react';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';
import { FaCoins, FaStar, FaStore, FaShoppingBag, FaUser, FaChartLine, FaShieldAlt } from 'react-icons/fa';

const ProfileModule = ({ title, children, className }) => (
  <div className={`bg-white rounded-lg shadow-md p-4 ${className}`}>
    <h3 className="text-lg font-semibold mb-2 text-gray-800">{title}</h3>
    {children}
  </div>
);

const Profile = ({ token, onLogout }) => {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchProfile = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const profileResponse = await axios.get('/profile');
      setProfile(profileResponse.data);
    } catch (error) {
      console.error('Error fetching profile:', error);
      if (error.response && error.response.status === 401) {
        onLogout();
        navigate('/login');
      } else {
        setError(error.response?.data?.msg || 'Error fetching profile');
      }
    } finally {
      setIsLoading(false);
    }
  }, [onLogout, navigate]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  if (isLoading) return <div className="text-center mt-8">Loading...</div>;
  if (error) return <div className="text-red-500 text-center mt-8">{error}</div>;
  if (!profile) return <div className="text-center mt-8">No profile data available.</div>;

  return (
    <div className="container mx-auto mt-8 p-4">
      <div className="relative mb-8 rounded-lg overflow-hidden h-80">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{ 
            backgroundImage: `url(https://iili.io/dgfxuaI.png)`,
          }}
        ></div>
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent z-10"></div>
        <img 
          src={profile.avatar} 
          alt={`${profile.username}'s character`}
          className="absolute inset-0 w-full h-full object-contain z-20"
        />
        <div className="absolute inset-0 flex items-end p-6 z-30">
          <div className="backdrop-blur-md bg-white/30 rounded-lg p-4">
            <h1 className="text-4xl font-bold text-white">{profile.username}</h1>
            <div className="mt-2 flex space-x-2">
              <span className="inline-flex items-center px-2 py-1 text-xs font-semibold text-white rounded-md bg-blue-500/80 whitespace-nowrap">
                <FaShieldAlt className="h-4 w-4 mr-1" />
                Level {profile.level}
              </span>
              <span className="inline-flex items-center px-2 py-1 text-xs font-semibold text-white rounded-md bg-green-500/80 whitespace-nowrap">
                <FaChartLine className="h-4 w-4 mr-1" />
                {profile.cards.length} Cards
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <ProfileModule title="Player Stats" className="col-span-1 md:col-span-2">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <div className="bg-blue-100 p-2 rounded-full mr-3">
                <FaUser className="text-blue-500" size={24} />
              </div>
              <div>
                <span className="text-sm text-gray-500">Level</span>
                <p className="text-xl font-semibold">{profile.level}</p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="bg-yellow-100 p-2 rounded-full mr-3">
                <FaCoins className="text-yellow-500" size={24} />
              </div>
              <div>
                <span className="text-sm text-gray-500">Gold</span>
                <p className="text-xl font-semibold">{profile.gold}</p>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div className="flex justify-between text-sm mb-1">
              <span className="text-gray-600">Experience</span>
              <span className="font-medium">{profile.experience} / {profile.nextLevelExp}</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-3">
              <div 
                className="bg-blue-600 h-3 rounded-full transition-all duration-300 ease-in-out" 
                style={{ width: `${(profile.experience / profile.nextLevelExp) * 100}%` }}
              ></div>
            </div>
          </div>
        </ProfileModule>

        <ProfileModule title="Backpack" className="col-span-1">
          <div className="flex items-center justify-between mb-4">
            <div className="bg-brown-100 p-2 rounded-full">
              <FaShoppingBag className="text-brown-500" size={24} />
            </div>
            <div>
              <span className="text-sm text-gray-500">Used Slots</span>
              <p className="text-xl font-semibold">
                {profile.backpack.usedSlots} / {profile.backpack.slots}
              </p>
            </div>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-3">
            <div 
              className="bg-brown-600 h-3 rounded-full transition-all duration-300 ease-in-out" 
              style={{ width: `${(profile.backpack.usedSlots / profile.backpack.slots) * 100}%` }}
            ></div>
          </div>
        </ProfileModule>

        <ProfileModule title="Merchant Skill" className="col-span-1 md:col-span-3">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <div className="bg-green-100 p-2 rounded-full mr-3">
                <FaStore className="text-green-500" size={24} />
              </div>
              <div>
                <span className="text-sm text-gray-500">Merchant Level</span>
                <p className="text-xl font-semibold">{profile.merchant.level}</p>
              </div>
            </div>
            <span className="text-sm bg-green-100 text-green-800 px-2 py-1 rounded-full">
              {profile.merchant.level} / {profile.merchant.maxLevel}
            </span>
          </div>
          <div className="mb-2">
            <div className="flex justify-between text-sm mb-1">
              <span className="text-gray-600">Merchant Experience</span>
              <span className="font-medium">{profile.merchant.experience} / {profile.merchant.nextLevelExp || 'Max'}</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-3">
              <div 
                className="bg-green-600 h-3 rounded-full transition-all duration-300 ease-in-out" 
                style={{ width: `${(profile.merchant.experience / (profile.merchant.nextLevelExp || profile.merchant.experience)) * 100}%` }}
              ></div>
            </div>
          </div>
        </ProfileModule>
      </div>
    </div>
  );
};

export default Profile;