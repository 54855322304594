// src/components/ItemSelection.js

import React from 'react';
import { FaClock, FaStar, FaLock } from 'react-icons/fa';

const ItemSelection = ({ items, onSelectItem, selectedCard, inventoryFull, activityEmoji, farmingSlotsAvailable }) => {
  const handleItemSelect = (item) => {
    if (farmingSlotsAvailable) {
      onSelectItem(item);
    }
  };

  return (
    <div className="relative">
      <h3 className="text-2xl font-semibold mb-4">Select an Item to Farm</h3>
      <p className="mb-4">Farming with: {selectedCard.name}</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {items.map((item) => {
          const canFarm = farmingSlotsAvailable;
          return (
            <div 
              key={item._id} 
              className={`relative bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-lg transition-shadow duration-200 ${!canFarm ? 'opacity-50' : ''}`}
              onClick={() => canFarm && handleItemSelect(item)}
            >
              <img src={item.image} alt={item.name} className="w-full h-48 object-cover" />
              <div className="p-4">
                <h4 className="font-bold text-lg mb-2">{item.name}</h4>
                <p className="text-sm text-gray-600 mb-2">Required Level: {item.minLevel}</p>
                <div className="flex items-center text-sm text-gray-600 mb-1">
                  <FaClock className="mr-1" /> {item.timeTaken} seconds per item
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <FaStar className="mr-1" /> {item.experience} XP per item
                </div>
              </div>
              {!canFarm && (
                <div className="absolute inset-0 bg-black bg-opacity-30 backdrop-filter backdrop-blur-sm flex items-center justify-center">
                  <div className="text-white text-center p-4 bg-gray-800 bg-opacity-80 rounded-lg shadow-lg">
                    <FaLock className="mx-auto mb-2" size={28} />
                    <p className="font-semibold">
                      {!farmingSlotsAvailable 
                        ? "No Farming Slots Available" 
                        : "Cannot Farm"
                      }
                    </p>
                    <p className="text-sm mt-1 text-gray-300">
                      {!farmingSlotsAvailable 
                        ? "Wait for a job to finish or buy more slots." 
                        : "Unable to farm this item."
                      }
                    </p>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ItemSelection;