// src/components/Farming.js

import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from '../axiosConfig';
import { toast } from 'react-toastify';
import { useUser } from '../contexts/UserContext';
import { FaPlus, FaLock, FaClock, FaStar, FaCoins, FaArrowUp, FaTimes } from 'react-icons/fa';
import { IoTrashOutline } from 'react-icons/io5';

const farmingActivities = [
  { name: 'Mining', emoji: '⛏️', path: '/farming/mining' },
  { name: 'Woodcutting', emoji: '🪓', path: '/farming/woodcutting' },
  { name: 'Fishing', emoji: '🎣', path: '/farming/fishing' },
  { name: 'Herbalism', emoji: '🌿', path: '/farming/herbalism' },
  { name: 'Treasure Hunting', emoji: '💎', path: '/farming/treasure-hunting' },
];

const FarmingSlotCard = ({ job, onJobComplete, onJobCancel }) => {
  const [progress, setProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [nextItemTimeLeft, setNextItemTimeLeft] = useState(0);

  useEffect(() => {
    const startTime = new Date(job.startTime).getTime();
    const endTime = new Date(job.endTime).getTime();
    const nextItemTime = new Date(job.nextItemTime).getTime();
    const duration = endTime - startTime;

    const updateTimes = () => {
      const now = Date.now();
      const elapsed = now - startTime;
      const newProgress = Math.min((elapsed / duration) * 100, 100);
      
      const timeRemaining = Math.max(0, endTime - now);
      const nextItemTimeRemaining = Math.max(0, nextItemTime - now);
      
      setProgress(newProgress);
      setTimeLeft(timeRemaining);
      setNextItemTimeLeft(nextItemTimeRemaining);

      if (timeRemaining === 0) {
        clearInterval(interval);
        onJobComplete(job);
      }
    };

    updateTimes(); // Initial update
    const interval = setInterval(updateTimes, 1000);

    return () => clearInterval(interval);
  }, [job, onJobComplete]);

  const formatTime = (ms) => {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const activityEmoji = farmingActivities.find(activity => activity.name.toLowerCase() === job.skillName.toLowerCase())?.emoji || '🔨';

  return (
    <div className="relative bg-gray-800 rounded-lg shadow-lg overflow-hidden border border-gray-600 h-48 active-farming-job">
      <div className="absolute inset-0 bg-cover bg-center opacity-20 filter blur-[2px]" style={{backgroundImage: `url(${job.cardImage})`}}></div>
      <div className="relative p-4 h-full flex flex-col justify-between z-10">
        <div className="flex justify-between items-start">
          <h4 className="text-xl font-semibold text-white truncate shadow-text">{job.cardName}</h4>
          <button
            onClick={() => onJobCancel(job)}
            className="text-red-500 hover:text-red-700 transition-colors duration-200 absolute top-2 right-2"
            title="Cancel Farming Job"
          >
            <FaTimes size={20} />
          </button>
        </div>
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center">
            <img src={job.itemImage} alt={job.itemName} className="w-12 h-12 rounded-full mr-3 border-2 border-yellow-500" />
            <div>
              <p className="text-base font-semibold text-white">{job.itemName}</p>
              <p className="text-xs text-gray-400">{job.skillName}</p>
            </div>
          </div>
          <div className="text-4xl animate-bounce">
            {activityEmoji}
          </div>
        </div>
        <div>
          <div className="mb-2">
            <div className="w-full bg-gray-600 rounded-full h-3">
              <div 
                className="bg-gradient-to-r from-blue-400 to-blue-600 h-3 rounded-full transition-all duration-300 ease-out"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
          <div className="flex justify-between items-center text-xs">
            <span className="text-gray-300 flex items-center">
              <FaClock className="mr-1 text-yellow-500" />{formatTime(timeLeft)}
            </span>
            <span className="text-gray-300 flex items-center">
              <FaClock className="mr-1 text-green-500" />Next: {formatTime(nextItemTimeLeft)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const FarmingStatus = ({ jobs, onJobComplete }) => {
  const clearFarmingHistory = async () => {
    try {
      await axios.delete('/profile/farming-history');
      onJobComplete();
      toast.success('Farming history cleared successfully');
    } catch (error) {
      console.error('Error clearing farming history:', error);
      toast.error('Failed to clear farming history');
    }
  };

  return (
    <div className="mt-8">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-2xl font-bold text-gray-800">Farming History</h3>
        <button 
          onClick={clearFarmingHistory}
          className="text-red-500 hover:text-red-700 font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-110"
          title="Clear Farming History"
        >
          <IoTrashOutline size={24} />
        </button>
      </div>
      {jobs.length === 0 ? (
        <p className="text-center text-gray-600">No completed farming jobs in the history</p>
      ) : (
        <div className="space-y-4">
          {jobs.map((job) => (
            <div key={job.jobId} className="relative bg-white rounded-lg shadow-md overflow-hidden border border-gray-200">
              <div className="absolute inset-0 opacity-10 bg-no-repeat bg-center bg-cover filter blur-sm" style={{
                backgroundImage: `url(${job.cardImage})`,
                imageRendering: 'auto'
              }}></div>
              <div className="relative p-4 z-10">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center">
                    <img 
                      src={job.itemImage} 
                      alt={job.itemName}
                      className="w-12 h-12 object-cover rounded-lg mr-3 border-2 border-yellow-500 shadow-md"
                      style={{imageRendering: 'auto'}}
                    />
                    <div>
                      <h4 className="font-bold text-lg">{job.itemName}</h4>
                      <p className="text-sm text-gray-600">{job.skillName}</p>
                    </div>
                  </div>
                  <span className="bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                    Completed
                  </span>
                </div>
                <div className="flex items-center text-sm mb-2">
                  <img 
                    src={job.cardImage} 
                    alt={job.cardName} 
                    className="w-8 h-8 object-cover rounded-full mr-2 border-2 border-blue-500"
                    style={{imageRendering: 'auto'}}
                  />
                  <span>Used: {job.cardName}</span>
                </div>
                <div className="text-sm">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <FaStar className="text-blue-500 mr-1" />
                      <span>{job.skillName}: +{job.totalExperienceGained} XP</span>
                    </div>
                    {job.skillLevelUps > 0 && (
                      <span className="text-green-500 flex items-center">
                        <FaArrowUp className="mr-1" />
                        +{job.skillLevelUps}
                      </span>
                    )}
                  </div>
                  <div className="flex items-center justify-between mt-1">
                    <div className="flex items-center">
                      <FaStar className="text-purple-500 mr-1" />
                      <span>Player: +{Math.floor(job.totalExperienceGained / 10)} XP</span>
                    </div>
                    {job.playerLevelUps > 0 && (
                      <span className="text-green-500 flex items-center">
                        <FaArrowUp className="mr-1" />
                        +{job.playerLevelUps}
                      </span>
                    )}
                  </div>
                  <div className="flex items-center justify-between mt-1">
                    <span className="text-blue-500 flex items-center">
                      <FaClock className="mr-1" />
                      Items: {job.totalItemsFarmed}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Farming = () => {
  const [farmingSlots, setFarmingSlots] = useState({ total: 1, used: 0, price: 1000 });
  const [userGold, setUserGold] = useState(0);
  const [activeJobs, setActiveJobs] = useState([]);
  const [completedJobs, setCompletedJobs] = useState([]);
  const { fetchUserInfo } = useUser();

  const fetchFarmingData = useCallback(async () => {
    try {
      const [profileResponse, farmingStatusResponse] = await Promise.all([
        axios.get('/profile'),
        axios.get('/profile/farming-status')
      ]);

      const activeJobsData = farmingStatusResponse.data.filter(job => job.status === 'active');
      const completedJobsData = farmingStatusResponse.data.filter(job => job.status === 'completed');

      setFarmingSlots({
        total: profileResponse.data.farmingSlots.total,
        used: activeJobsData.length,
        price: profileResponse.data.farmingSlots.price
      });
      setUserGold(profileResponse.data.gold);
      setActiveJobs(activeJobsData);
      setCompletedJobs(completedJobsData);
    } catch (error) {
      console.error('Error fetching farming data:', error);
      toast.error('Failed to load farming data');
    }
  }, []);

  useEffect(() => {
    fetchFarmingData();
    const interval = setInterval(fetchFarmingData, 5000);
    return () => clearInterval(interval);
  }, [fetchFarmingData]);

  const handleBuySlot = async () => {
    try {
      const response = await axios.post('/profile/buy-farming-slot');
      setFarmingSlots(prev => ({
        ...prev,
        total: response.data.newTotalSlots,
        price: response.data.newSlotPrice
      }));
      setUserGold(response.data.newGold);
      fetchUserInfo();
      toast.success('New farming slot purchased successfully!');
    } catch (error) {
      console.error('Error buying farming slot:', error);
      toast.error(error.response?.data?.msg || 'Failed to buy farming slot');
    }
  };

  const handleJobComplete = (completedJob) => {
    setActiveJobs(prev => prev.filter(job => job.jobId !== completedJob.jobId));
    setCompletedJobs(prev => [completedJob, ...prev]);
    fetchFarmingData();
  };

  const handleJobCancel = async (jobToCancel) => {
    if (window.confirm('Are you sure you want to cancel this farming job? Progress will be added to history.')) {
      try {
        const response = await axios.post('/profile/cancel-farming-job', { jobId: jobToCancel.jobId });
        setActiveJobs(prev => prev.filter(job => job.jobId !== jobToCancel.jobId));
        setCompletedJobs(prev => [response.data, ...prev]);
        toast.success('Farming job cancelled successfully');
        fetchFarmingData();
      } catch (error) {
        console.error('Error cancelling farming job:', error);
        toast.error('Failed to cancel farming job');
      }
    }
  };

  return (
    <div className="container mx-auto mt-8 p-4">
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Farming Activities</h2>
      
      {/* Farming Activities */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-8">
        {farmingActivities.map((activity) => (
          <Link
            key={activity.name}
            to={activity.path}
            className={`flex flex-col items-center justify-center p-4 bg-gradient-to-br from-gray-700 to-gray-800 rounded-lg shadow-lg transition-all duration-300 ${
              farmingSlots.used >= farmingSlots.total ? 'opacity-50 cursor-not-allowed' : 'hover:from-gray-600 hover:to-gray-700 transform hover:scale-105'
            }`}
            onClick={(e) => farmingSlots.used >= farmingSlots.total && e.preventDefault()}
          >
            <span className="text-4xl mb-2" role="img" aria-label={activity.name}>
              {activity.emoji}
            </span>
            <span className="text-white font-semibold">{activity.name}</span>
            {farmingSlots.used >= farmingSlots.total && (
              <FaLock className="text-red-500 mt-2" />
            )}
          </Link>
        ))}
      </div>

{/* Farming Slots */}
<div className="mb-8 bg-gradient-to-br from-gray-800 to-gray-900 p-6 rounded-lg shadow-xl">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold text-white">Active Farming Jobs</h3>
          <div className="flex items-center">
            <span className="text-gray-300 mr-4">Slots: {farmingSlots.used} / {farmingSlots.total}</span>
            <button
              onClick={handleBuySlot}
              disabled={userGold < farmingSlots.price}
              className={`bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded-full flex items-center transition duration-300 ease-in-out transform hover:scale-105 ${userGold < farmingSlots.price ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <FaPlus className="mr-2" /> Buy Slot ({farmingSlots.price} <FaCoins className="inline ml-1" />)
            </button>
          </div>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {activeJobs.map(job => (
            <FarmingSlotCard key={job.jobId} job={job} onJobComplete={handleJobComplete} onJobCancel={handleJobCancel} />
          ))}
          {[...Array(farmingSlots.total - farmingSlots.used)].map((_, index) => (
            <div key={`empty-${index}`} className="bg-gray-700 rounded-lg p-4 flex items-center justify-center border border-gray-600 shadow-inner h-40">
              <span className="text-gray-400">Empty Slot</span>
            </div>
          ))}
        </div>
      </div>

      {/* Farming History */}
      <FarmingStatus jobs={completedJobs} onJobComplete={fetchFarmingData} />
    </div>
  );
};

export default Farming;