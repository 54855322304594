import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaCoins, FaUserCircle, FaCaretDown } from 'react-icons/fa';
import { useUser } from '../contexts/UserContext';

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { userInfo, isAuthenticated, logout } = useUser();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!isAuthenticated || !userInfo) return null;

  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-end items-center">
        <div className="flex items-center">
          <span className="text-yellow-400 mr-4 flex items-center">
            <FaCoins className="mr-1" /> {userInfo.gold}
          </span>
          <div className="relative" ref={dropdownRef}>
            <button
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className="text-white flex items-center focus:outline-none"
            >
              <FaUserCircle className="mr-2" />
              {userInfo.username} (Lvl {userInfo.level})
              <FaCaretDown className="ml-1" />
            </button>
            {dropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                <Link
                  to="/profile"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => setDropdownOpen(false)}
                >
                  Profile
                </Link>
                <button
                  onClick={() => {
                    logout();
                    setDropdownOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;