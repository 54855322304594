import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import SidebarToggle from './components/SidebarToggle';
import RegisterForm from './components/RegisterForm';
import LoginForm from './components/LoginForm';
import Profile from './components/Profile';
import Inventory from './components/Inventory';
import OpenPacks from './components/OpenPacks';
import Cards from './components/Cards';
import Farming from './components/Farming';
import FarmingActivity from './components/FarmingActivity';
import { UserProvider, useUser } from './contexts/UserContext';

function ProtectedRoute({ children }) {
  const { isAuthenticated, userInfo } = useUser();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userInfo !== null) {
      setIsLoading(false);
    }
  }, [userInfo]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to="/login" replace />;
}

function AppContent() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Router>
      <div className="flex h-screen">
        <Sidebar isOpen={isSidebarOpen} />
        <div className={`flex flex-col flex-1 overflow-hidden transition-all duration-300 ${isSidebarOpen ? 'md:ml-56' : 'ml-0'}`}>
          <Navbar />
          <div className="flex-1 overflow-y-auto bg-gray-100">
            <SidebarToggle toggleSidebar={toggleSidebar} isOpen={isSidebarOpen} />
            <div className="container mx-auto p-4">
              <Routes>
                <Route path="/register" element={<RegisterForm />} />
                <Route path="/login" element={<LoginForm />} />
                <Route 
                  path="/profile" 
                  element={<ProtectedRoute><Profile /></ProtectedRoute>} 
                />
                <Route 
                  path="/inventory" 
                  element={<ProtectedRoute><Inventory /></ProtectedRoute>} 
                />
                <Route 
                  path="/cards" 
                  element={<ProtectedRoute><Cards /></ProtectedRoute>} 
                />
                <Route 
                  path="/open-packs" 
                  element={<ProtectedRoute><OpenPacks /></ProtectedRoute>} 
                />
                <Route 
                  path="/farming" 
                  element={<ProtectedRoute><Farming /></ProtectedRoute>} 
                />
                <Route 
                  path="/farming/:activity" 
                  element={<ProtectedRoute><FarmingActivity /></ProtectedRoute>} 
                />
                <Route
                  path="/"
                  element={<ProtectedRoute><Navigate to="/profile" replace /></ProtectedRoute>}
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer 
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Router>
  );
}

function App() {
  return (
    <UserProvider>
      <AppContent />
    </UserProvider>
  );
}

export default App;