import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';
import CardComponent from './CardComponent';

const Cards = () => {
  const [cards, setCards] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axios.get('/profile/cards');
        setCards(response.data);
      } catch (error) {
        console.error('Error fetching cards:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else {
          setError(error.response?.data?.msg || 'Error fetching cards');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchCards();
  }, [navigate]);

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  }

  return (
    <div className="container mx-auto mt-8 p-4">
      <h2 className="text-2xl font-bold mb-6">Your Cards</h2>
<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 p-6">
  {cards.map((card, index) => (
    <CardComponent key={`${card._id}-${index}`} card={card} uniqueKey={`${card._id}-${index}`} />
  ))}
</div>
    </div>
  );
};

export default Cards;