// src/components/SidebarToggle.js
import React from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';

const SidebarToggle = ({ toggleSidebar, isOpen }) => {
  return (
    <button
      onClick={toggleSidebar}
      className={`fixed top-4 z-50 bg-gray-800 text-white p-2 rounded-full shadow-lg hover:bg-gray-700 focus:outline-none transition-all duration-300 md:hidden ${isOpen ? 'left-68' : 'left-4'}`}
    >
      {isOpen ? <FaTimes /> : <FaBars />}
    </button>
  );
};

export default SidebarToggle;